/* Google Font Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
.form-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-layout .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.form-layout .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.form-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url("../static/loginPageImage.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: var(--gray);
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.55);
}
.form-section .login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.form-section .logo {
  text-align: center;
  font-size: 18px;
}
.form-section .logo img {
  height: 140px;
  width: auto;
}
.form-section .login {
  height: 350px;
}
.form-section .login-title {
  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
  color: white !important;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--secondary-bg);
  z-index: 5;
}
.form-section .register {
  height: auto;
}
.form-section .form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  width: 300px;
  padding: 15px 25px 15px;
}
.form-section .form .form-group-label {
  top: 10px;
  font-size: 0.75rem;
}
.form-section .form-title {
  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--secondary-bg);
  z-index: 5;
}
.form-section .form-group {
  position: relative;
  z-index: 10;
  display: flex;
}
.form-section .form-group-label {
  position: absolute;
  z-index: 150;
  color: var(--gray);
  font-size: 0.9rem;
  top: 30px;
  left: 18px;
  transition: all 300ms ease-in;
}
.form-section .form-group-input {
  width: 100%;
  position: relative;
  z-index: 100;
  background: rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
  border-radius: 4px;
  border: none;
  opacity: 1;
  font-size: 1rem;
  color: var(--secondary-bg);
  line-height: 50px;
  padding: 12px 15px 0;
}
.form-section .form-sign-in {
  margin: 0px 0 10px;
  padding: 16px;
  color: var(--secondary-bg);
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.form-section .form-secondary-cta {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
}
.form-section .form-secondary-cta-text {
  opacity: 1;
  font-size: 0.9rem;
  color: var(--gray);
  text-decoration: none;
}
.form-section .form-secondary-cta-text--need-help {
  transition: all 0.3s ease-in;
}
.form-section .form-secondary-cta-text--need-help:hover {
  text-decoration: underline;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #11101D;
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open {
  width: 250px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  /* color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease; */
  width: 85%;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  margin-top: 20px;
  height: 100%;
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1d1b31;
  color: #FFF;
}

.sidebar.open .bx-search:hover {
  background: #1d1b31;
  color: #FFF;
}

.sidebar .bx-search:hover {
  background: #FFF;
  color: #11101d;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #11101D;
}

.sidebar li a:hover {
  background: #FFF;
}

.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #11101D;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #1d1b31;
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open li.profile {
  width: 250px;
}

.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}

.sidebar li.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section {
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
.profile-div {
  background-color: white;
  flex-wrap: wrap;
  border-style: groove;
  border-color: darkblue;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  border-width: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.profile-div .profile-image-div {
  border-width: 5px;
  height: 100%;
}
.profile-div .profile-info {
  margin-left: 10px;
  margin: 5px;
}
.profile-div .profile-info .profile-info-item {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
}
.profile-div .profile-info .profile-info-item .profile-info-key {
  font-weight: bold;
}
.profile-div .profile-info .profile-info-item .profile-info-value {
  padding-left: 10px;
  font-weight: normal;
}

.base-container404 {
  background-color: rgb(17, 16, 28);
  color: white;
}
.base-container404 #main404 {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
  margin: 0%;
}

.container404 {
  display: table-cell;
  vertical-align: middle;
}
.container404 h1 {
  color: var(--netflix-color-bg);
  font-family: "Lato", sans-serif;
  display: inline-block;
  font-size: 50px;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
  font-style: italic;
}
.container404 h2 {
  color: var(--netflix-color-bg);
  font-family: "Lato", sans-serif;
  font-size: 30px;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
* {
  margin: 0;
  padding: 0;
}

.container-creator {
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.creator-card-wrapper {
  width: 400px;
  height: 500px;
  position: relative;
}

.creator-card {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 320px;
  height: 320px;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.5s;
}

.creator-card .creator-card-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgb(0, 0, 0);
  transition: 0.5s;
}

.creator-card .creator-card-image img {
  display: block;
  width: 100%;
}

.creator-card:hover img {
  display: block;
  width: 100%;
  opacity: 0.4;
  transition: 0.5s;
}

.creator-card:hover .creator-card-image {
  transform: translateY(-100px);
  transition: all 0.9s;
}

/**** Social Icons *****/
.creator-social-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
}

.creator-social-icons li {
  list-style: none;
}

.creator-social-icons li a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fff;
  font-size: 23px;
  color: #333;
  font-weight: bold;
  margin: 0 6px;
  transition: 0.4s;
  transform: translateY(200px);
  opacity: 0;
}

.creator-card:hover .creator-social-icons li a {
  transform: translateY(0px);
  opacity: 1;
}

.creator-social-icons li a:hover {
  background: rgb(60, 76, 137);
  transition: 0.2s;
}

.creator-social-icons li a:hover .fab {
  color: #fff;
}

.creator-social-icons li a .fab {
  transition: 0.8s;
}

.creator-social-icons li a .fab:hover {
  transform: rotateY(360deg);
  color: #fff;
}

.creator-card:hover li:nth-child(1) a {
  transition-delay: 0.1s;
}

.creator-card:hover li:nth-child(2) a {
  transition-delay: 0.2s;
}

.creator-card:hover li:nth-child(3) a {
  transition-delay: 0.3s;
}

.creator-card:hover li:nth-child(4) a {
  transition-delay: 0.4s;
}

/**** Personal Details ****/
.creator-details {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 120px;
  z-index: 1;
  padding: 10px;
}

.creator-details h2 {
  margin: 30px 0;
  padding: 0;
  text-align: center;
}

.creator-details h2 .job-title {
  font-size: 1rem;
  line-height: 2.5rem;
  color: #333;
  font-weight: 300;
}

.uploader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.picture-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  height: 90%;
  border: 1px solid #66688d;
  background-color: rgb(17, 16, 28);
  border-radius: 5px;
  padding: 1% 0%;
}
.picture-container .picture-box {
  max-width: 40%;
  max-height: 40%;
  margin: 2% 0px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #04232e;
}
.picture-container .picture-box:hover {
  opacity: 0.8;
  background-color: #adc8e6;
  border-color: #adc5e6;
}
.picture-container .picture-box .picture {
  width: 100%;
  height: 1000%;
  object-fit: cover;
  border-radius: 5px;
}
.picture-container .picture-box .unknown-picture {
  display: block;
  width: 500px;
  height: 500px;
  background-color: #66688d;
  border-radius: 5px;
}

.button-update {
  background: #09095d;
  border-radius: 999px;
  box-shadow: #4d4d96 0 5px 10px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.button-upload {
  background: white;
  color: #09095d;
  border-radius: 999px;
  box-shadow: #4d4d96 0 5px 10px -5px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 5px 15px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.button-download {
  text-decoration: none;
  margin: 10px;
  background: white;
  color: #09095d;
  border-radius: 999px;
  box-shadow: #4d4d96 0 5px 10px -5px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 5px 15px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

/*# sourceMappingURL=app.css.map */
